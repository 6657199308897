import { useQuery } from "@tanstack/react-query";
import { useGlobalState } from "./useGlobalState";
import { defaultSupabaseClient } from "../supabaseClient";
import { DbResult } from "../types";

type NoGoal = {
  hasGoalSet: false;
};

type Gaol = {
  hasGoalSet: true;
  id: number;
  goal: number;
};

export const useGoal = () => {
  const state = useGlobalState();

  return useQuery({
    queryKey: ["goal", state.id, state.month, state.year],
    queryFn: async (): Promise<Gaol | NoGoal> => {
      const query = defaultSupabaseClient
        .from("goals")
        .select("*")
        .eq("activity_id", state.id!)
        .eq("month", state.month)
        .eq("year", state.year)
        .maybeSingle();

      const goal: DbResult<typeof query> = await query;

      if (goal.error) throw goal.error;

      if (goal.data)
        return {
          hasGoalSet: true,
          id: goal.data.id,
          goal: goal.data.goal,
        };

      if (!state.isCurrentDate)
        return {
          hasGoalSet: false,
        };

      const newGoal = await defaultSupabaseClient
        .from("goals")
        .insert({
          goal: 400,
          month: state.month,
          year: state.year,
          activity_id: state.id,
        })
        .select()
        .single();

      if (newGoal.error) throw newGoal.error;

      return {
        hasGoalSet: true,
        id: newGoal.data.id,
        goal: newGoal.data.goal,
      };
    },
    enabled: !!state.id,
  });
};
