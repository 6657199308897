import { useQuery } from "@tanstack/react-query";
import { defaultSupabaseClient } from "../supabaseClient";

export const useChartData = (goalId: number) =>
  useQuery({
    queryKey: ["chart-data", goalId],
    queryFn: async () => {
      const query = defaultSupabaseClient.rpc("count_by_day", {
        goal_id_arg: goalId,
      });
      const { data, error } = await query;
      if (error) throw error;
      return data.map((e) => ({
        day: new Date(e.day).valueOf(),
        total: e.total,
      }));
      //   const map = new Map<Date, number>();
      //   for (const row of data) map.set(new Date(row.day), row.total);
      //   return map;
    },
  });
