import { Anchor, Card, Group, Stack, Text, rem } from "@mantine/core";
import { useRecentProgress } from "../Hooks/useRecentProgress";

// const formatOpacity = (index: number, total: number) => {
//   if (index + 3 === total) return 0.6;
//   if (index + 2 === total) return 0.3;
//   if (index + 1 === total) return 0.1;
//   return 1;
// };

const RecentRecords = (props: { goalId: number }) => {
  const { data } = useRecentProgress(props.goalId);
  return (
    <>
      {data?.map((r) => (
        <Card withBorder p="sm" key={r.id.toString()}>
          <Group justify="space-between" align="center">
            <Text>{r.count}</Text>
            <Stack gap={0} align="flex-end">
              <Text c="dimmed" size={rem(10)}>
                3s
              </Text>
              <Text c="dimmed" size={rem(10)}>
                <Anchor c="dimmed">Delete</Anchor>
              </Text>
            </Stack>
          </Group>
        </Card>
      ))}
    </>
  );
};

export default RecentRecords;
