import {
  Button,
  Group,
  MantineProvider,
  TextInput,
  createTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Session } from "@supabase/supabase-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Home from "./Components/Home";
import { defaultSupabaseClient } from "./supabaseClient";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";

const queryClient = new QueryClient();

const handleSubmit = async ({ email }: { email: string }) => {
  const { error } = await defaultSupabaseClient.auth.signInWithOtp({ email });

  if (error) {
    alert(error.cause || error.message);
  } else {
    alert("Check your email for the login link!");
  }
};

const Login = () => {
  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <TextInput
        withAsterisk
        label="Email"
        placeholder="your@email.com"
        {...form.getInputProps("email")}
      />

      <Group mt="md">
        <Button type="submit">Submit</Button>
      </Group>
    </form>
  );
};

const AuthWrapper = () => {
  const [session, setSession] = useState<Session>();

  useEffect(() => {
    defaultSupabaseClient.auth.getSession().then((s) => {
      if (s.data.session) setSession(s.data.session);
    });

    defaultSupabaseClient.auth.onAuthStateChange((_, session) => {
      if (session) setSession(session);
    });
  }, []);

  return session ? <Home /> : <Login />;
};

const theme = createTheme({
  defaultRadius: "md",
});

export default function App() {
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <QueryClientProvider client={queryClient}>
          <MantineProvider defaultColorScheme="light" theme={theme}>
            <Routes>
              <Route path="/" element={<AuthWrapper />} />
            </Routes>
          </MantineProvider>
        </QueryClientProvider>
      </QueryParamProvider>
    </BrowserRouter>
  );
}
