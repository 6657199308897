import { useEffect, useState } from "react";
import { useQueryParam, NumberParam } from "use-query-params";

const getCurrentDate = () => {
  const now = new Date();

  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

export const useGlobalState = () => {
  const [currentDate, setDate] = useState(getCurrentDate());
  const [month, setMonth] = useQueryParam("month", NumberParam);
  const [year, setYear] = useQueryParam("year", NumberParam);
  const [id] = useQueryParam("id", NumberParam);

  useEffect(() => {
    const interval = setInterval(() => {
      const c = getCurrentDate();
      if (c.valueOf() > currentDate.valueOf()) setDate(getCurrentDate());
    }, 5000);
    return () => clearInterval(interval);
  }, [currentDate]);

  const m = month ?? currentDate.getMonth() + 1;
  const y = year ?? currentDate.getFullYear();

  return {
    month: m,
    year: y,
    currentDate,
    isCurrentDate:
      currentDate.getMonth() + 1 === m && currentDate.getFullYear() === y,
    id: id ?? undefined,
    setMonth,
    setYear,
  };
};
