import { MonthPickerInput } from "@mantine/dates";
import React from "react";
import { useGlobalState } from "../Hooks/useGlobalState";

const MonthPicker = () => {
  const state = useGlobalState();
  const value = new Date(state.year, state.month - 1);

  return (
    <MonthPickerInput
      value={value}
      onChange={(date) => {
        if (date) {
          state.setMonth(date.getMonth() + 1);
          state.setYear(date.getFullYear());
        }
      }}
      maxDate={state.currentDate}
    />
  );
};

export default MonthPicker;
