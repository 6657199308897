import {
  Popover,
  UnstyledButton,
  Card,
  Center,
  Stack,
  Group,
  NumberInput,
  Button,
  Checkbox,
  Text,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import React, { useState } from "react";
import { useGoal } from "../Hooks/useGoal";

const EditGoal = () => {
  const { status, data } = useGoal();
  const { ref, hovered } = useHover();
  const [opened, setOpened] = useState(false);

  if (status !== "success") return <React.Fragment />;

  return (
    <Popover withArrow shadow="md" opened={opened} onChange={setOpened}>
      <Popover.Target>
        <UnstyledButton
          onClick={() => setOpened((o) => !o)}
          disabled={!data.hasGoalSet}
        >
          <Card withBorder ref={ref}>
            <Center>
              <Text c="blue" fw={700}>
                {data.hasGoalSet
                  ? hovered || opened
                    ? "Edit"
                    : "Target: " + data.goal
                  : "No data"}
              </Text>
            </Center>
          </Card>
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <Group>
            <NumberInput />
            <Button onClick={() => setOpened(false)}>Save</Button>
          </Group>
          <Checkbox defaultChecked label="Make default" />
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default EditGoal;
