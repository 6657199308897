import { Card, Stack, Text, useMantineTheme } from "@mantine/core";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { curveBasis } from "@visx/curve";
import { localPoint } from "@visx/event";
import { GridColumns, GridRows } from "@visx/grid";
import { scaleLinear, scaleTime } from "@visx/scale";
import { Bar, Line, LinePath } from "@visx/shape";
import { Threshold } from "@visx/threshold";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { bisector } from "@visx/vendor/d3-array";
import { timeFormat } from "@visx/vendor/d3-time-format";
import React, { useCallback, useMemo } from "react";
import { useChartData } from "../Hooks/useChartData";
import { useGlobalState } from "../Hooks/useGlobalState";

type ProgressData = {
  day: Date;
  target: number;
  targetRounded: number;
  actual: number;
  completed: number;
};

const width = 700;
const height = 441;

const margin = { top: 20, right: 20, bottom: 45, left: 40 };

// bounds
const xMax = width - margin.left - margin.right;
const yMax = height - margin.top - margin.bottom;

// bounds
const innerWidth = width - margin.left - margin.right;
const innerHeight = height - margin.top - margin.bottom;

const formatDate = timeFormat("%d %b");

const buildProgressData = (
  data: { day: number; total: number }[],
  month: number,
  year: number,
  goal: number
) => {
  const daysInMonth = new Date(year, month, 0).getDate();

  const results: ProgressData[] = [];

  const map = new Map<number, number>();
  for (const d of data) map.set(d.day, d.total);

  for (let i = 1; i <= daysInMonth; i++) {
    var previous = i === 1 ? 0 : results[i - 2].actual;

    const day = new Date(year, month - 1, i);
    const tTarget = (goal / daysInMonth) * i;
    const actual = (map.get(day.valueOf()) || 0) + previous;
    const targetRounded = Math.round(tTarget);

    const data = {
      day: day,
      target: tTarget,
      targetRounded: targetRounded,
      actual: actual,
      completed: map.get(day.valueOf()) || 0,
    };

    results.push(data);
  }

  const last = results[results.length - 1];

  const timeScale = scaleTime<number>({
    domain: [results[0].day, last.day],
  });

  const countScale = scaleLinear<number>({
    domain: [0, Math.max(last.actual, last.target)],
    nice: true,
  });

  timeScale.range([0, xMax]);
  countScale.range([yMax, 0]);

  return {
    data: results,
    timeScale,
    countScale,
  };
};

const bisectDate = bisector<ProgressData, Date>((d) => new Date(d.day)).left;

const ThresholdProgressChart = (props: {
  goalId: number;
  goal: number;
  data: { day: number; total: number }[];
  month: number;
  year: number;
}) => {
  const theme = useMantineTheme();
  const { currentDate } = useGlobalState();

  const { data, timeScale, countScale } = useMemo(
    () => buildProgressData(props.data, props.month, props.year, props.goal),
    [props.goal, props.data, props.month, props.year]
  );

  const showDayLine =
    currentDate.valueOf() >= data[0].day.valueOf() &&
    currentDate.valueOf() <= data[data.length - 1].day.valueOf();

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<ProgressData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const handleTooltip = useCallback(
    (
      event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>
    ) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = timeScale.invert(x);

      const index = bisectDate(data, x0, 1);
      const d = data[index - 1];

      showTooltip({
        tooltipLeft: timeScale(d.day),
        tooltipTop: countScale(d.target),
        tooltipData: d,
      });
    },
    [showTooltip, data, timeScale, countScale]
  );

  return (
    <>
      <svg ref={containerRef} width={width} height={height}>
        <GridRows
          scale={countScale}
          width={xMax}
          height={yMax}
          stroke="#737579"
          opacity={0.2}
        />
        <GridColumns
          scale={timeScale}
          width={xMax}
          height={yMax}
          stroke="#737579"
          opacity={0.2}
        />
        <Threshold
          clipBelowTo={yMax}
          clipAboveTo={0}
          data={data}
          curve={curveBasis}
          x={(d) => timeScale(d.day)}
          y0={(d) => countScale(d.actual)}
          y1={(d) => countScale(d.target)}
          id={"chart1"}
          belowAreaProps={{
            fill: theme.colors.red[8],
            fillOpacity: 0.4,
          }}
          aboveAreaProps={{
            fill: theme.colors.green[8],
            fillOpacity: 0.4,
          }}
        />
        <LinePath
          data={data}
          curve={curveBasis}
          x={(d) => timeScale(d.day)}
          y={(d) => countScale(d.target)}
          stroke="#737579"
          strokeWidth={1}
          strokeOpacity={0.8}
          strokeDasharray="1,2"
        />
        {showDayLine && !tooltipOpen && (
          <Line
            from={{
              x: timeScale(currentDate),
              y: 0,
            }}
            to={{
              x: timeScale(currentDate),
              y: countScale(0),
            }}
            stroke="#737579"
          />
        )}
        {tooltipOpen && (
          <Line
            from={{
              x: tooltipLeft,
              y: 0,
            }}
            to={{
              x: tooltipLeft,
              y: countScale(0),
            }}
            stroke="#737579"
          />
        )}
        <LinePath
          data={data}
          curve={curveBasis}
          x={(d) => timeScale(d.day)}
          y={(d) => countScale(d.actual)}
          stroke="#737579"
          strokeWidth={1}
        />
        <AxisBottom
          top={yMax}
          scale={timeScale}
          numTicks={15}
          tickLabelProps={{ width: 20, y: 30, fill: "#737579" }}
          tickStroke="#737579"
          tickFormat={(d) =>
            new Intl.DateTimeFormat("en-US", {
              weekday: "short",
              day: "numeric",
            }).format(d as Date)
          }
          stroke="#737579"
        />
        <AxisLeft
          left={0}
          scale={countScale}
          tickLabelProps={{
            width: 40,
            scaleToFit: "shrink-only",
            fill: "#737579",
          }}
          tickStroke="#737579"
          stroke="#737579"
        />
        <Bar
          x={0}
          y={0}
          width={innerWidth}
          height={innerHeight + 10}
          fill="transparent"
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
        />
        {tooltipData && (
          <circle
            cx={tooltipLeft}
            cy={tooltipTop}
            r={3}
            fill="#737579"
            pointerEvents="none"
          />
        )}
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          <Stack gap={0}>
            <Text fw={600} size="xs">
              {formatDate(tooltipData.day)}
              {tooltipData.completed > 0 ? (
                <Text span inherit c="green">
                  {" "}
                  +{tooltipData.completed}
                </Text>
              ) : null}
            </Text>
            <Text size="xs">Target: {tooltipData.targetRounded}</Text>
            <Text size="xs">
              Completed:{" "}
              <Text
                span
                inherit
                fw={600}
                c={
                  tooltipData.actual >= tooltipData.targetRounded
                    ? "green"
                    : "red"
                }
              >
                {tooltipData.actual}
              </Text>
            </Text>
          </Stack>
        </TooltipInPortal>
      )}
    </>
  );
};

const ProgressChart = (props: { goalId: number; goal: number }) => {
  const { month, year } = useGlobalState();
  const { data, status } = useChartData(props.goalId);

  if (data)
    return (
      <Card withBorder p={0}>
        <ThresholdProgressChart
          {...props}
          data={data}
          month={month}
          year={year}
          goal={props.goal}
          goalId={props.goalId}
        />
      </Card>
    );

  return <Text>{status}</Text>;
};

export default ProgressChart;
