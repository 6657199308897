import { Button, Card } from "@mantine/core";
import { useGlobalState } from "../Hooks/useGlobalState";
import {
  useQueryClient,
  useMutation,
  QueryClient,
} from "@tanstack/react-query";
import { defaultSupabaseClient } from "../supabaseClient";
import { Row } from "../types";

const onSuccess = (queryClient: QueryClient, data: Row<"records">) => {
  queryClient.setQueryData(
    ["recent-progress", data.goal_id],
    (old?: Row<"records">[]) => (old ? [data, ...old] : old)
  );

  queryClient.setQueryData(
    ["chart-data", data.goal_id],
    (
      old?: {
        day: number;
        total: number;
      }[]
    ) => {
      const createdAt = new Date(data.created_at);
      const day = new Date(
        createdAt.getFullYear(),
        createdAt.getMonth(),
        createdAt.getDate()
      ).valueOf();
      // todo fix if the day doesnt exist yet
      return old
        ? old.map((e) => {
            if (e.day !== day) {
              return e;
            }
            return {
              day: e.day,
              total: e.total + data.count,
            };
          })
        : old;
    }
  );
};

const AddRecords = (props: { goalId: number }) => {
  const { isCurrentDate } = useGlobalState();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (count: number) => {
      const { error, data } = await defaultSupabaseClient
        .from("records")
        .insert({
          goal_id: props.goalId,
          count: count,
        })
        .select();
      if (error) throw error;
      return data[0];
    },
    onSuccess: (data) => onSuccess(queryClient, data),
  });

  return (
    <Card withBorder p={0}>
      <Button.Group orientation="vertical">
        <Button disabled={!isCurrentDate} onClick={() => mutation.mutate(5)}>
          +5
        </Button>
        <Button disabled={!isCurrentDate} onClick={() => mutation.mutate(10)}>
          +10
        </Button>
        <Button disabled={!isCurrentDate} onClick={() => mutation.mutate(20)}>
          +20
        </Button>
      </Button.Group>
    </Card>
  );
};

export default AddRecords;
