import { useQuery } from "@tanstack/react-query";
import { DbResult } from "../types";
import { defaultSupabaseClient } from "../supabaseClient";

export const useActivities = () =>
  useQuery({
    queryKey: ["activities"],
    queryFn: async () => {
      const query = defaultSupabaseClient
        .from("activities")
        .select("*")
        .order("id", { ascending: true });
      const { data, error }: DbResult<typeof query> = await query;
      if (error) throw error;
      return data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
