import {
  ActionIcon,
  Affix,
  AppShell,
  Burger,
  Button,
  Card,
  Group,
  Menu,
  RingProgress,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  UnstyledButton,
  useMantineColorScheme,
} from "@mantine/core";
import { useDisclosure, useHover } from "@mantine/hooks";
import {
  IconArrowsMoveHorizontal,
  IconChartLine,
  IconDotsVertical,
} from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { NumberParam, useQueryParam } from "use-query-params";
import { useActivities } from "../Hooks/useActivities";
import { useGoal } from "../Hooks/useGoal";
import { defaultSupabaseClient } from "../supabaseClient";
import { Row } from "../types";
import AddRecords from "./AddRecords";
import EditGoal from "./EditGoal";
import MonthPicker from "./MonthPicker";
import ProgressChart from "./ProgressChart";
import RecentRecords from "./RecentRecords";

const AddActivity = () => {
  const [name, setName] = useState("");
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (name: string) => {
      const { error } = await defaultSupabaseClient
        .from("activities")
        .insert({
          activity: name,
          default_goal: 200,
        })
        .select();
      if (error) throw error;
    },
    onSuccess: () => {
      setName("");
      queryClient.refetchQueries(["activities"]);
    },
  });

  return (
    <Group grow>
      <TextInput
        placeholder="Activity name"
        value={name}
        onChange={(event) => setName(event.currentTarget.value)}
      />
      <Button
        disabled={name.length === 0 || mutation.isLoading}
        onClick={() => mutation.mutate(name)}
      >
        Add
      </Button>
    </Group>
  );
};

const ActivityMenu = ({ id }: { id: number }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (id: number) => {
      const { error } = await defaultSupabaseClient
        .from("activities")
        .delete()
        .eq("id", id);
      if (error) throw error;
    },
    onSuccess: (_, id) => {
      queryClient.setQueryData(["activities"], (old?: Row<"activities">[]) =>
        old ? old.filter((e) => e.id !== id) : old
      );
    },
  });

  return (
    <Menu shadow="md" width={200} withinPortal>
      <Menu.Target>
        <IconDotsVertical size={18} />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item>Rename</Menu.Item>
        <Menu.Item
          color="red"
          //icon={<IconTrash size={14} />}
          onClick={() => mutation.mutate(id)}
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const ActivityList = () => {
  const activities = useActivities();
  const [, setId] = useQueryParam("id", NumberParam);

  return (
    <Stack>
      {activities.data?.map((e) => (
        <UnstyledButton key={e.id} onClick={() => setId(e.id, "replace")}>
          <Card style={{ width: 400 }} withBorder>
            <Group align="center" justify="space-between">
              <Group>
                <Text>{e.activity}</Text>
              </Group>
              <ActivityMenu id={e.id} />
            </Group>
          </Card>
        </UnstyledButton>
      ))}
      <AddActivity />
    </Stack>
  );
};

// const AddRecord = ({ id }: { id: number }) => {
//   const queryClient = useQueryClient();
//   const mutation = useMutation({
//     mutationFn: async (count: number) => {
//       const { error, data } = await defaultSupabaseClient
//         .from("records")
//         .insert({
//           goal_id: id,
//           count: count,
//         })
//         .select();
//       if (error) throw error;
//       return data[0];
//     },
//     onSuccess: (data, count) => {
//       queryClient.setQueryData(
//         ["recent-progress", id],
//         (old?: Row<"records">[]) => (old ? [data, ...old] : old)
//       );
//       queryClient.setQueryData(["progress-count", id], (old?: number) =>
//         old ? old + count : old
//       );
//     },
//   });

//   return (
//     <Button.Group>
//       <Button
//         disabled={mutation.isLoading}
//         variant="default"
//         onClick={() => mutation.mutate(10)}
//       >
//         +10
//       </Button>
//       <Button
//         disabled={mutation.isLoading}
//         variant="default"
//         onClick={() => mutation.mutate(20)}
//       >
//         +20
//       </Button>
//       <Button
//         disabled={mutation.isLoading}
//         variant="default"
//         onClick={() => mutation.mutate(40)}
//       >
//         +40
//       </Button>
//     </Button.Group>
//   );
// };

const ProgressCount = () => {
  const { hovered, ref } = useHover();

  // let sum = 0;

  // for (let value of testData.values()) {
  //   sum += value;
  // }

  // const percentComplete = Math.round((sum / testTarget) * 100);

  return (
    <Card withBorder ref={ref}>
      <RingProgress
        sections={[{ value: 0, color: "blue" }]}
        roundCaps
        label={
          <Text c="blue" fw={700} ta="center" size="xl">
            {hovered ? 0 : 0 + "%"}
          </Text>
        }
      />
    </Card>
  );
};

const ActivityProgress = ({ id }: { id: number }) => {
  const goal = useGoal();

  return (
    <Stack>
      <Group align="flex-start">
        <Stack>
          <MonthPicker />
          <ProgressCount />
          <EditGoal />
          {goal.data?.hasGoalSet ? (
            <AddRecords goalId={goal.data.id} />
          ) : (
            <React.Fragment />
          )}
          {goal.data?.hasGoalSet ? (
            <RecentRecords goalId={goal.data.id} />
          ) : (
            <React.Fragment />
          )}
        </Stack>
        {goal.data?.hasGoalSet ? (
          <ProgressChart goalId={goal.data.id} goal={goal.data.goal} />
        ) : (
          <React.Fragment />
        )}
      </Group>
    </Stack>
  );
};

const Home = () => {
  const [id] = useQueryParam("id", NumberParam);
  const [opened, { toggle }] = useDisclosure();
  const { toggleColorScheme } = useMantineColorScheme();

  return (
    <>
      <Affix position={{ bottom: 20, right: 20 }}>
        <ThemeIcon size="xl" radius="xl">
          <IconArrowsMoveHorizontal />
        </ThemeIcon>
      </Affix>
      <AppShell
        header={{ height: 80 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { mobile: !opened, desktop: true },
        }}
        padding="md"
      >
        <AppShell.Header>
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <Group h="100%" px="xl" justify="space-between">
            <Group>
              <ThemeIcon radius="xl" size="lg">
                <IconChartLine size={20} />
              </ThemeIcon>
              <Text fw={300}>activity.znder.co.uk</Text>
            </Group>
            <ActionIcon onClick={toggleColorScheme}></ActionIcon>
          </Group>
        </AppShell.Header>

        <AppShell.Navbar p="md">Navbar</AppShell.Navbar>

        <AppShell.Main>
          <Group align="flex-start">
            <ActivityList />
            {id && <ActivityProgress id={id} />}
          </Group>
        </AppShell.Main>
      </AppShell>
    </>
  );
};

export default Home;
