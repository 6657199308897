import { useQuery } from "@tanstack/react-query";
import { DbResult } from "../types";
import { defaultSupabaseClient } from "../supabaseClient";

export const useRecentProgress = (goalId: number) => {
  return useQuery({
    queryKey: ["recent-progress", goalId],
    queryFn: async () => {
      const query = defaultSupabaseClient
        .from("records")
        .select("*")
        .eq("goal_id", goalId)
        .order("created_at", { ascending: false })
        .range(0, 10);
      const { data, error }: DbResult<typeof query> = await query;
      if (error) throw error;
      return data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
